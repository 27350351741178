<template>
  <RsModal
    :title="$t('navbar.language')"
    :icon-close="IcChevronLeft"
    modal-window-classes="max-h-full !h-auto"
    class="items-end"
    @dismiss="closeLocaleSwitcher"
  >
    <div class="font-bold mb-4">
      {{ $t('navbar.changeLanguage') }}
    </div>
    <div class="locale-switcher">
      <RsField
        v-for="locale in availableLocales"
        :key="`locale-${locale}`"
        wrapper-class="locale-switcher__option"
        @click="selectNewLocale(locale)"
      >
        {{ localePathEntity.getLocaleName(locale) }}
        <template v-if="newCurrentLocale === locale" #rightIcon>
          <IcTrailing class="text-rs-primary" width="24" />
        </template>
      </RsField>
    </div>
    <template #footer>
      <div class="flex justify-end w-full">
        <RsButton :id="`gtm-mobile-save-locale-${newCurrentLocale}`" @click="saveNewLocale">
          {{ $t('common.saveClose') }}
        </RsButton>
      </div>
    </template>
  </RsModal>
</template>

<script setup lang="ts">
import useLocale from '~/composables/useLocale'
import { useLocalePathEntity } from '~/composables/entities'
import { IcTrailing, IcChevronLeft } from '~/components/common/icons'

const emit = defineEmits(['commit'])

const { currentLocale, availableLocales } = useLocale()
const newCurrentLocale = ref<string>(currentLocale.value)
const localePathEntity = useLocalePathEntity()

const selectNewLocale = (locale: string) => newCurrentLocale.value = locale

function saveNewLocale () {
  localePathEntity.selectNewLanguage(newCurrentLocale.value)
  emit('commit', { isChanged: true })
}

function closeLocaleSwitcher () {
  emit('commit', { isChanged: false })
}
</script>

<style scoped>
.locale-switcher {
  @apply flex flex-col items-center;
}
.locale-switcher__option {
  @apply w-full mb-4;
}
</style>
